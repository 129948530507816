<template>
  <liquor-tree
    class="tree--small"
    :data="data"
    :options="options"/>
</template>

<script>

import LiquorTree from 'liquor-tree';

export default {
  name: 'custom-tree',

  components: {

    LiquorTree,
  },
  props: {

    data: {

      required: true,
      type: Array,
    },
    options: {

      required: true,
      type: Object,
    },
  },
};

</script>

<style scoped>
    .tree-node.selected>.tree-content {
        background: none !important; /* plugin override */
    }

    .tree-node:not(.selected)>.tree-content:hover {
        background: none !important; /* plugin override */
    }
    .tree-node:not(.has-child) .tree-arrow {
        position: relative;
        margin-left: 0;
        width: 30px;
    }

    .tree-node:not(.has-child) .tree-arrow:after {
        position: absolute;
        border-top: 1px solid;
        left: 9px;
        top: 50%;
        height: 9px;
        width: 9px;
        transform-origin: center;
    }
</style>
